<template>
  <div class="table-box">
    <div class="lb_table table">
      <el-table :data="data" v-loading="loading" @row-click="editNeigh">
        <el-table-column label="ID" prop="id"  width="100"></el-table-column>
        <el-table-column label="NAME" prop="name"></el-table-column>
        <el-table-column label="LASY UPDATED">
          <template slot-scope="scope">
            <div>{{ scope.row.updated_at | dateFormat }}</div>
          </template>
        </el-table-column>
        <el-table-column label="UPDATED BY" prop="user"></el-table-column>
        <el-table-column width="70">
          <template slot-scope="scope">
            <!-- <el-tooltip class="item"  effect="light" content="Edit" placement="top-start"> -->
              <i class="el-icon-edit-outline" @click="edit(scope.row)"></i>
            <!-- </el-tooltip> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Page :total="total" :pageSize="10" @number="pagesChanged"></Page>
    <Neigheorhood-Edit ref="show" :infor="infor" @update="update"></Neigheorhood-Edit>
  </div>
</template>
<script>
import { apiGetAreas, apiUpdateArea, apiCreateArea } from "../../../API/api";
import NeigheorhoodEdit from "./NeigheorhoodEditDialog";
import Page from "../../common/Paging";
export default {
  components: { NeigheorhoodEdit, Page },
  data() {
    return {
      infor: {},
      loading: false,
      data: [],
      limit: 10,
      total: 1, // 总页数
      currentPage: 1, // 当前页数
      show: false,
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {
      return {
        limit: this.limit,
        offset: this.offset,
      };
    },
  },
  methods: {
    editNeigh(row){
      this.infor = row;
      this.$refs.show.show = true;
    },
    clone(val) {
      delete val.id;
      apiCreateArea(val).then((res) => {
        this.getAreas();
      });
    },
    update(val) {
      apiUpdateArea(val.id, val)
        .then((res) => {
          this.getAreas();
        })
        .finally(() => {
          this.$refs.show.show = false;
        });
    },
    edit(val) {
      this.infor = val;
      this.$refs.show.show = true;
    },
    pagesChanged() {
      this.currentPage = number;
      this.getAreas();
    },
    getAreas() {
      this.loading = true;
      apiGetAreas(this.query)
        .then((res) => {
          this.data = res.results;
          this.total = res.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getAreas();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
@import "../Dialog.scss";
.table-box {
  padding-bottom: 20px;
  .lb_table {
    font-size: 16px;
    /deep/ .el-table {
      td,
      th {
        text-align: left;
        padding: 15px 0;
        &:first-of-type{
          padding: 15px;
        }
      }
    }
    i {
      color: #678993;
    }
  }
}
</style>