<template>
  <div class="covid">
    <Covid-Table></Covid-Table>
    <div class="covid_btn">
      <el-button @click="add">+ Add New</el-button>
    </div>
    <Covid-Edit ref="show" :infor="infor"></Covid-Edit>
  </div>
</template>
<script>
import CovidEdit from "./Covid/CovidEditDialog";
import CovidTable from "./Covid/CovidTable";
import { apiGetQAs, apiDelQA, apiUploadQA } from "../../API/api";
export default {
  data() {
    return {
      infor: {},
      loading: false,
      data: [],
      limit: 10,
      totalPage: 1, // 总页数
      currentPage: 1, // 当前页数
    };
  },
  components: { CovidEdit, CovidTable },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {
      return {
        limit: this.limit,
        offset: this.offset,
      };
    },
  },
  methods: {
    cancelDel(id) {
      apiDelQA(id).then((res) => {
        this.getCovids();
      });
    },
    add() {
      this.infor = {};
      this.$refs.show.show = true;
      // this.$router.push({ name: "resources-covid-create" });
    },
    edit(val) {
      // this.infor = val;
      // this.$refs.show.show = true;
      this.$router.push({
        name: "resources-covid-edit",
        params: { id: val.qa_id },
      });
    },
    changeStatus(val, index, row) {
      let data = {
        publish: val,
      };
      apiUploadQA(row.qa_id, data)
        .then((res) => {
          let a = this.data[index];
          a.publish = val;
          this.$set(this.data, index, a);
          this.$message.success("Set successfully");
        })
        .catch((error) => {
          this.$message.error("Setting failed");
        });
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.covid {
  position: relative;
  margin-top: 10px;
  .covid_btn {
    position: absolute;
    top: -60px;
    right: 0px;
    z-index: 33;
    .el-button {
      color: #678993;
      background-color: #fff;
      font-size: 14px;
      width: 180px;
      font-family: "Roboto-Bold", sans-serif;
      border: none;
      text-align: right;
    }
  }
}
</style>