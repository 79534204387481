<template>
  <div class="restaurants">
    <Neigheorhoods-Table ref="summary"></Neigheorhoods-Table>
    <div class="btn">
      <el-button @click="$refs.editShow.show = true"
        >+ Add Neigheorhood</el-button
      >
    </div>
    <Neigheorhood-Edit
      ref="editShow"
      :infor="infor"
      type="creatType"
      @createdArea="createdArea"
    ></Neigheorhood-Edit>
  </div>
</template>
<script>
import NeigheorhoodEdit from "./Neigheorhoods/NeigheorhoodEditDialog";
import NeigheorhoodsTable from "./Neigheorhoods/NeigheorhoodsTable";
import { apiCreateArea } from "../../API/api";
export default {
  components: { NeigheorhoodEdit, NeigheorhoodsTable },
  data() {
    return {
      activeName: "first",
      infor: {},
    };
  },
  methods: {
    add() {
      this.infor = {};
      this.$refs.editShow.show = true;
    },
    createdArea(val) {
      apiCreateArea(val).then((res) => {
        this.$refs.summary.getAreas();
        this.$refs.detail.getAreas();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.restaurants {
  position: relative;
  margin-top: 10px;
  .btn {
    position: absolute;
    top: -60px;
    right: 0px;
    z-index: 33;
    .el-button {
      color: #678993;
      background-color: #fff;
      font-size: 14px;
      width: 180px;
      font-family: "Roboto-Bold", sans-serif;
      border: none;
      text-align: right;
    }
  }
}
</style>