<template>
  <div class="resource-box">
    <!-- <div class="sr_string">
      <div class="sr_bar sr_identically">
        <div class="exterior">
          <div class="sr_list">
            <router-link
              class="sr_fo"
              exact
              :to="{name:'resources-restaurants'}"
            >RESTAURANTS</router-link>
          </div>
        </div>
        <div class="exterior">
          <div class="sr_list">
            <router-link
              class="sr_fo"
              exact
              :to="{name:'resources-neigheorhoods'}"
            >NEIGHBORHOODS</router-link>
          </div>
        </div>
        <div class="exterior">
          <div class="sr_list">
            <router-link
              class="sr_fo"
              exact
              :to="{name:'resources-covid'}"
            >COVID-19</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="sr_small">
      <p @click="show=!show">
        <img src="../../assets/img/house_small.png" alt />
      </p>
      <div v-show="show" class="shownav">
        <div>
          <div class="big_title" @click="flushCom">
            <div class="title_argument">
              <router-link
                exact
                :to="$route.params.id ? '/lease/' + $route.params.id : '/lease'"
              >RESTAURANTS</router-link>
            </div>
          </div>
          <div class="big_title" @click="flushCom">
            <div class="title_argument">
              <router-link
                exact
                :to="'/lease/' + $route.params.id + '/payment'"
              >NEIGHBORHOODS</router-link>
            </div>
          </div>
          <div class="big_title" @click="flushCom">
            <div class="title_argument">
              <router-link  exact :to="'/lease/' + $route.params.id + '/agreement'">COVID-19</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view> -->
    <p class="resource-title">Resource</p>
    <div class="resource-back">
      <div class="row resource-tab">
        <div class="tab-pane" :class="{ active: tabNumber == 1 }" @click="tab(1)">
          Restaurants
        </div>
        <div class="tab-pane" :class="{ active: tabNumber == 2 }" @click="tab(2)">
          Neighborhoods
        </div>
        <div  class="tab-pane" :class="{ active: tabNumber == 3 }" @click="tab(3)">
          COVID-19
        </div>
      </div>
      <div class="move">
        <el-select v-model="value"  placeholder @change="tabNav">
          <el-option  label="Restaurants" value="1"></el-option>
          <el-option  label="Neighborhoods" value="2"></el-option>
          <el-option  label="COVID-19" value="3"></el-option>
        </el-select>
     </div>
      <div class="content">
        <Restaurants v-show="tabNumber === 1"></Restaurants>
        <Neighborhoods v-show="tabNumber === 2"></Neighborhoods>
        <Covids v-show="tabNumber === 3"></Covids>
      </div>
    </div>
  </div>
</template>
<script>
import Restaurants from "./Restaurants";
import Neighborhoods from "./Neigheorhoods";
import Covids from "./Covid";
export default {
  components: { Restaurants, Neighborhoods, Covids },
  data() {
    return {
      show: false,
      activeName: "first",
      tabNumber: 1,
      value: "1"
    };
  },
  methods: {
    flushCom() {
      this.show = false;
    },
    tabNav(){
      this.tabNumber = Number(this.value);
    },
    tab(number) {
      this.tabNumber = number;
    },
  },
};
</script>
<style lang="scss" scoped>
.resource-box {
  .resource-title {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 24px;
    color: #36425d;
    text-align: left;
  }
  .resource-back {
    background-color: #fff;
    //
    .resource-tab {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        left: 0;
        border-bottom: 0.017857rem solid #ebeef5;
        bottom: 0;
        width: 100%;
      }
      .tab-pane {
        color: #737a8c;
        font-size: 16px;
        font-family: "Roboto-Regular", sans-serif;
        margin-right: 40px;
        padding: 20px 0 20px 20px;
        &:hover{
          cursor: pointer;
        }
      }
      .active {
        color: #678993;
        font-size: 16px;
        font-family: "Roboto-Bold", sans-serif;
      }
    }
  }
  .move{display: none;text-align: left;padding: 20px;}
  @media (max-width:992px) {
    .move{display: block;}
    .resource-back .resource-tab{display: none;}
  }
}
// .siade_right {
//   font-size: 18px;
//    background: #ffffff 0% 0% no-repeat padding-box;
//   /deep/ .el-divider--horizontal {
//     margin: 0;
//   }
//   .sr_identically,
//   .sr_small {
//     background: #ffffff 0% 0% no-repeat padding-box;
//     border-radius: 10px 10px 0 0;
//   }
//   .sr_bar {
//     display: flex;
//     justify-content: flex-start;
//     .exterior {
//       position: relative;
//       padding: 20px 0;
//     }
//     .sr_Middle {
//       height: 20px;
//       width: 0px;
//       border: 1px solid rgb(216, 209, 209);
//       margin: 3px 0 5px 40px;
//     }
//     .sr_list {
//       height: 30px;
//       display: flex;
//       align-items: center;
//       text-align: left;
//       padding: 0 15px 0 15px;
//       font-size: 20px;
//       font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
//       font-weight: 600;
//       color: #808080;
//       .sr_img {
//         display: inline-block;
//         margin-right: 10px;
//         img {
//           width: 100%;
//           height: 100%;
//         }
//       }
//       &:hover {
//         cursor: pointer;
//         color: #678993;
//       }
//     }
//   }
// }

// .sr_small {
//   display: none;
//   padding: 10px 0 10px 40px;
//   text-align: left;
//   position: relative;
//   .shownav {
//     position: absolute;
//     background: #ffffff 0% 0% no-repeat padding-box;
//     box-shadow: 0px 10px 10px #0000000d;
//     border-radius: 10px;
//     width: 330px;
//     left: 0px;
//     top: 100px;
//     z-index: 3;
//     padding-left: 40px;
//     .big_title {
//       .title_argument {
//         font-size: 20px;
//         font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
//         font-weight: 600;
//         color: #808080;
//       }
//       &:hover {
//         cursor: pointer;
//       }
//     }
//   }
// }

// .router-link-active {
//   color: #678993;
// }

// .sr_list .router-link-active::after {
//   content: "";
//   text-align: center;
//   position: absolute;
//   left: 50%;
//   bottom: 0;
//   transform: translateX(-50%);
//   border: 2px solid #678993;
//   width: 90px;
// }

// @media screen and (max-width: 1550px) {
//   .siade_right .sr_bar .sr_list {
//   }
// }

// @media screen and (max-width: 1480px) {
//   .siade_right .sr_bar .sr_list {
//     font-size: 18px;
//   }
// }

// @media screen and (max-width: 700px) {
//   .sr_string {
//     display: none;
//   }

//   .sr_small {
//     display: block;
//   }
// }
</style>
