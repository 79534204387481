<template>
  <div class="check">
    <el-dialog
      title="Receive Payment"
      :modal-append-to-body="false"
      :visible.sync="show"
    >
      <div class="check-content">
        <div class="check-add">
          <el-form :inline="true" :model="data" class="demo-form-inline">
          
              <el-form-item>
                <p class="add-tip">Category</p>
                <el-select v-model="data.category" filterable placeholder>
                  <el-option v-for="item in categorys" :key="item.label" :label="item.name" :value="item.label"></el-option>
                </el-select>
              </el-form-item>
       
            <el-form-item>
              <p class="add-tip">Question</p>
              <tinymce v-model="data.question" ref="presentation" :height="30" />
              <!-- <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" v-model="data.question"></el-input> -->
            </el-form-item>
            <el-form-item>
              <p class="add-tip">Answer</p>
              <tinymce v-model="data.answer" ref="presentation" :height="80" />
              <!-- <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" v-model="data.answer"></el-input> -->
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button class="light-blue-button" @click="cancel">Cancel</el-button>
          <el-button class="primary-button" @click="saveCheck">Save</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Minixs } from "../../../js/mixins";
import { apiCreateQA, apiUploadQA } from "../../../API/api";
import Tinymce from "../../tinymce";
export default {
  components:{ Tinymce },
  mixins: [Minixs],
  props: ["infor"],
  data() {
    return {
      data: { category: "" },
      show: false,
      categorys: [
        { label: "Government Restrictions", name: "Government Restrictions" },
        { label: "Healthcare", name: "Healthcare" },
        {
          label: "Nantucket Guidelines Information",
          name: "Nantucket Guidelines & Information"
        },
        { label: "Shopping Dining", name: "Shopping & Dining" },
        { label: "Travel", name: "Travel" },
        { label: "Vacation Rentals", name: "Vacation Rentals" },
        // "Healthcare",
        // "Nantucket Guidelines & Information",
        // "Shopping & Dining",
        // "Travel",
        // "Vacation Rentals"
      ]
    };
  },
  methods: {
    saveCheck() {
      if (this.data.qa_id) {
        //  修改
        apiUploadQA(this.data.qa_id, this.data)
          .then(res => {
            this.$parent.getCovids();
          })
          .finally(() => {
            this.show = false;
          });
      } else {
        //  新建
        apiCreateQA(this.data)
          .then(res => {
            this.$parent.currentPage = 1;
            this.$parent.getCovids();
          })
          .finally(() => {
            this.show = false;
          });
      }
    },
    cancel(){

    }
  },
  watch: {
    show(oldV, newV) {
      this.data = Object.assign({}, this.infor);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../css/dialog.scss";
@import "../../../css/button.scss";
.check {
  text-align: left;
  .add-tip {
    margin: 0;
    font-size: 14px;
  }
  .check-add {
    /deep/ .el-form-item {
      margin: 0 0 10px 0;
      width: 100%;
    }
    /deep/ .el-form--inline .el-form-item__content,
    /deep/ .el-select {
      width: 100%;
    }
    .detail {
      /deep/ .el-textarea__inner {
        resize: none;
      }
      .add-tip {
        margin: 15px 0 10px 0;
      }
    }
  }
  .btn {
    text-align: right;
    margin-top: 20px;
  }
  /deep/ .el-dialog{width: 800px;}
  @media (max-width:992px) {
    /deep/ .el-dialog{width: 90%;}
  }
}
</style>