<template>
  <div class="check">
    <el-dialog title="Receive Payment" :modal-append-to-body="false" :visible.sync="show">
      <div class="check-content">
        <div class="check-add">
          <el-form :inline="true" :model="data" ref="data" class="demo-form-inline">
            <div class="row">
              <el-form-item
                prop="name"
                :rules="[ { required: true, message: 'this information is required', trigger: 'blur', }, ]">
                <p class="add-tip">Name</p>
                <el-input v-model="data.name" />
              </el-form-item>
            </div>
            <div class="upload-content">
              <p class="add-tip">Thumbnail</p>
              <el-upload class="avatar-uploader" action="upload" :http-request="uploadImagesThumbnail" :show-file-list="false">
                <img v-if="data.cover" :src="data.cover" class="avatar" />
                <div v-else class="avatar-uploader-icon">
                  <i class="el-icon-plus"></i>
                </div>
              </el-upload>
            </div>
            <div class="upload row">
              <div v-for="(item, index) in data.images" :key="index" class="pb_uploadimg pb_exhibit">
                <p class="add-tip">Picture</p>
                <div class="image-preview">
                  <img :src="item" alt />
                  <div class="pd_close">
                    <span class="pd_closeIcon" @click="removePic(index)">
                      <i class="el-icon-close"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div class="upload-content">
                <p class="add-tip">Picture</p>
                <el-upload class="avatar-uploader" action="upload" list-type="picture-card" :http-request="uploadImagesPicture" :show-file-list="false">
                  <div class="avatar-uploader-icon">
                    <i class="el-icon-plus"></i>
                  </div>
                </el-upload>
              </div>
            </div>
            <div class="detail">
              <p class="add-tip">Description</p>
              <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 9 }" v-model="data.description"></el-input>
            </div>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="addCheck('data')">Save</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { apiUploadImage } from "../../../API/api";
export default {
  props: ["infor"],
  data() {
    return {
      data: { cover: "", images: [] },
      show: false,
    };
  },
  methods: {
    // 删除相片
    removePic(index) {
      this.data.splice(index, 1);
    },
    uploadImagesPicture(param) {
      let form = new FormData();
      form.append("file", param.file);
      apiUploadImage(form)
        .then((res) => {
          this.data.images.push(res.url);
        })
        .catch((err) => {
          this.$message("Upload failure");
        });
    },
    // 封面
    uploadImagesThumbnail(param) {
      let form = new FormData();
      form.append("file", param.file);
      apiUploadImage(form).then((res) => {
          this.data.cover = res.url;
        }).catch((err) => {
          this.$message("Upload failure");
        });
    },
    addCheck(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.data.id) {
            this.$emit("update", this.data);
          } else {
            this.$emit("createdArea", this.data);
          }
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    show(oldV, newV) {
      this.data = Object.assign({}, this.infor);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/init.scss";
@import "../../../css/dialog.scss";
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.check {
  text-align: left;
  .add-tip {
    margin: 0;
  }
  .check-add {
    /deep/ .el-form-item {
      margin: 0 0 10px 0;
      width: 48%;
    }
    /deep/ .el-form--inline .el-form-item__content,
    /deep/ .el-input,
    /deep/ .el-select {
      width: 100%;
    }
    .upload {
      margin-top: 0px;
      justify-content: flex-start;
      -webkit-flex-wrap: wrap;
      display: -webkit-flex;
      flex-wrap: wrap;
    }
    .upload-content {
      margin-right: 15px;
      .add-tip {
        margin: 10px 0 10px 0;
      }
      &:nth-child(3) {
        margin-right: 0;
      }
    }

    .avatar-uploader-icon {
      font-size: 28px;
      width: 180px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff 0% 0% no-repeat padding-box;
      .el-icon-plus {
        color: $color1;
      }
      .avatar {
        width: 180px;
        height: 140px;
        display: block;
      }
    }
    .pb_uploadimg {
      text-align: left;
      width: 180px;
      margin: 0px 10px 0px 0;
      position: relative;
      .add-tip {
        margin: 10px 0 10px 0;
      }
      img {
        width: 180px;
        height: 140px;
        border-radius: 10px;
      }
      .pd_close {
        position: absolute;
        top: 39px;
        right: 0;
        background-color: #000;
        font-size: 0.254545rem;
        color: white;
        background: #000;
        opacity: 0.4;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        z-index: 9999;
        border-radius: 0 10px 0 0;
      }
    }
    .detail {
      /deep/ .el-textarea__inner {
        resize: none;
        font-family: "Roboto-Regular", sans-serif;
      }
      .add-tip {
        margin: 10px 0 10px 0;
      }
    }
    .avatar-uploader {
      /deep/ .el-upload--picture-card {
        width: 100%;
        border: none;
      }
      /deep/ .el-upload {
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 180px;
        height: 140px;
        .avatar-uploader-icon{
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          border: 1px dashed $color1;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .btn {
    text-align: center;
    .el-button {
      width: 200px;
      color: #fff;
      border-color: $color1;
      background-color: $color1;
      margin: 10px 0;
      font-size: 20px;
    }
  }
  /deep/ .el-dialog{width: 600px;}
  @media (max-width:992px) {
    /deep/ .el-dialog{width: 90%;}
  }
}
</style>