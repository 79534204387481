<template>
  <div class="table-box">
    <div class="lb_table table">
      <el-table :data="data" v-loading="loading">
        <el-table-column label="CATEGORY" prop="category" width="240">
          <template slot-scope="scope">
            <div @click="edit(scope.row)">
              <p v-if="scope.row.category === 'Government Restrictions'">
                Government Restrictions
              </p>
              <p v-else-if="scope.row.category === 'Healthcare'">Healthcare</p>
              <p v-else-if=" scope.row.category === 'Nantucket Guidelines Information' " >
                Nantucket Guidelines & Information
              </p>
              <p v-else-if="scope.row.category === 'Shopping Dining'">
                Shopping & Dining
              </p>
              <p v-else-if="scope.row.category === 'Travel'">Travel</p>
              <p v-else-if="scope.row.category === 'Vacation Rentals'">
                Vacation Rentals
              </p>
            </div>
          </template>
        </el-table-column> 
        <el-table-column label="QUESTION" min-width="200">
          <template slot-scope="scope">
            <span v-html="scope.row.question" @click="edit(scope.row)"></span>
          </template>
        </el-table-column>
        <el-table-column label="ANSWER" min-width="200">
          <template slot-scope="scope">
            <span class="desc_text" v-html="scope.row.answer" @click="edit(scope.row)" ></span>
          </template>
        </el-table-column>
        <el-table-column label="PUBLISH" width="140">
          <template slot-scope="scope">
            <el-switch @change="changeStatus($event, scope.$index, scope.row)" v-model="scope.row.publish" active-color="#678993" inactive-color="#ccc"></el-switch>
          </template>
        </el-table-column>
        <el-table-column width="70">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="light" content="Edit" placement="top-start">
            <i class="btn el-icon-edit-outline" @click="edit(scope.row)"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column width="70">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="light" content="Delete" placement="top-start">
            <i class="btn el-icon-delete" @click="cancelDel(scope.row.qa_id)"></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Page :total="total" :pageSize="10" @number="pagesChanged"></Page>
    <Covid-Dialog ref="covidShow" :infor="infor"></Covid-Dialog>
  </div>
</template>
<script>
import { apiGetQAs, apiDelQA, apiUploadQA } from "../../../API/api";
import CovidDialog from "./CovidEditDialog"
import Page from "../../common/Paging";
export default {
  components: { Page, CovidDialog },
  data() {
    return {
      infor: {},
      loading: false,
      data: [],
      limit: 10,
      total: 1, // 总页数
      currentPage: 1, // 当前页数
      show: false,
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {
      return {
        limit: this.limit,
        offset: this.offset,
      };
    },
  },
  methods: {
    cancelDel(id) {
      apiDelQA(id).then((res) => {
        this.getCovids();
      });
    },
    
    edit(val) {
      this.infor = val;
      this.$refs.covidShow.show = true;
    },
    update() {},
    pagesChanged() {
      this.currentPage = number;
      this.getCovids();
    },
    getCovids() {
      this.loading = true;
      apiGetQAs(this.query)
        .then((res) => {
          this.data = res.results;
          this.total = res.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getCovids();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
@import "../Dialog.scss";
.table-box {
  padding-bottom: 20px;
  .lb_table {
    font-size: 16px;
    .desc_text {
      height: 50px;
      font-family: "Roboto-Medium", sans-serif;
      padding: 0 0 10px 0;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    /deep/ .el-table {
      td,
      th {
        text-align: left;
        padding: 15px 0;
        &:first-of-type{
          padding: 15px;
        }
      }
    }
    i {
      font-weight: 600;
      color: #678993;
    }
  }
}
</style>